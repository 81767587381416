export const buildStartingChildPath = (step_number) => {
    if ((step_number || "").trim() == "") {
        return "0"
    }

    let arr = (step_number + '').split(/\./);
    /*
    I am forgetting why I named this function as buildStartingChildPath versus just buildChildPath.
    We need to study closely how we construct the step_number, and use it in the UI, and how we use it here.
    When step_number is an empty string (called on the root element), the split function above return an array of one element (the empty string) 
    rather than an array of zero element.  This is not what I was expecting.  I was expecting it to return an 
    array of zero element.  Without checking for this condition and explicitly setting arr to an empty array, we end up with 0.NaN because 
    calling parseInt on an empty string, and then subtract 1 does not make sense (parseInt does not convert an empty string into 0).

    In the below code, we call unshift(0) to add 0 to the beginning of the arr array.  This is to account for the root task (every child task 
    must have a root task).  This may mean that this function can be used to construct the index path for the root task as well (when called on the root 
    task, the function probably should return just '0'.)

    Because this function was returning '0.NaN' when called on the root task, we have hard-coded NaN (a quick hack to get the output for the root task
    to appear correctly under the root task).  We are in a time crunch currently, and I do not have enough time to appropriately think and test this, so 
    I am resetting arr to an empty array for now.  Later on, we will need to come back to this, search the code for NaN, and do the appropriate thing.

    if (step_number + '' == '') {
        arr = [];
    }
    */

    let i = 0;
    for (i = 0; i < arr.length; i++) {
        arr[i] = parseInt(arr[i]) - 1; 
    }
    arr.unshift(0);
    let starting_child_path = arr.join('.')
    return starting_child_path;
}
