export const getTopRunbookTaskId = () => {
    /*
    if (window.location.pathname.includes('/tasks/')) {
        let url = window.location.pathname.substring('/tasks/'.length);
        if (url.indexOf('?') > 0) {
            url = url.substring(0, url.indexOf('?'));
        }
        let runbook_task_id = url;
        return runbook_task_id;  
    } else {
        return '';
    }
    */
    const route = useRoute();
    return route.params.id;
}