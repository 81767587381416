<template>
    <!--components/ParamsList.vue-->
    <div class="class_param_list">
        <div style="text-align:center; position:relative" class="input_output_bg white_bottom" :class="{'inputs': props.type=='input', 'outputs': props.type=='output'}" v-if="((! props.is_execute) || ((props.is_execute) && (props.list.length > 0)))">{{ header }}
            <i class="fa-light fa-plus" @click="add_empty_row" v-if="! disabled_ref.valueOf()"/>
        </div>
        <!--
            v-for="(subtask, index) in task_ref.valueOf().sub_tasks" :key="index"
        -->
        <div v-for="(p, index) in props.list.valueOf()" :key="index" style="position: relative;" class="white_bottom input_output_bg d-flex">
            <div  class="class_input_type_selector input_output_fields_container">
                <select class="custom-select" v-model="p.param_type" :disabled="((props.readonly) || (props.is_execute))">
                    <option value="" selected="selected">Select type</option>
                    <option value="String">String</option>
                    <option value="int">Integer</option>
                    <option value="float">Float</option>
                    <option value="Boolean">Boolean</option>
                    <option value="Array">Array</option>
                    <option value="Object">Object / Dictionary</option>
                    <option value="Dropdown">Dropdown</option>
                    <option value="File">File</option>
                </select>
            </div>
            <div v-if="props.type == 'input'" style="width: 115px; background: white; color: black; border-radius: 5px; margin: 5px 10px" class="class_required_field_checkbox input_output_fields_container">
                <label>
                    <input type="checkbox" v-model="p.required" :disabled="((props.readonly) || (props.is_execute))"/> required
                </label>
            </div>

            <div :class="{'input_fields_container': props.type == 'input', 'output_fields_container': props.type == 'output'}" style="width: 250px">
                <input class="input_output_fields class_param_name" v-model="p.name" :disabled="disabled_ref.valueOf()" :placeholder="props.type == 'input' ? 'input name ': 'output name'" style="width: 100%"/>
            </div>

            <div class="input_output_fields_container" style="padding-left: 5px; padding-right: 5px;">
                <i :class="{'fa-solid': true, 'fa-left': (header == 'inputs'), 'fa-right': (header == 'outputs')}" />
            </div>

            <div :class="{'input_fields_container': props.type == 'input', 'output_fields_container': props.type == 'output'}" style="flex: 1">
                <input v-if="(p.name.toLowerCase().includes('password') || p.name.toLowerCase().includes('secret') || p.name.toLowerCase().includes('token'))" type="password" class="input_output_fields" v-model="p.value" style="width: 100%" :disabled="((disabled_ref.valueOf()) && (! props.is_execute))" :placeholder="props.type == 'input' ? 'default value / variable name' : 'variable name'"/>            
                <select v-else-if="(p.param_type === 'Dropdown') && (props.is_execute)" class="input_output_fields" v-model="p.value" style="width: 100%" :disabled="((disabled_ref.valueOf()) && (! props.is_execute))" :placeholder="props.type == 'input' ? 'default value / variable name' : 'variable name'">
                    <option v-for="option in p.options" :value="option">{{option}}</option>
                </select>
                <div v-else-if="p.param_type === 'File'">
                    <input  class="input_output_fields" ref="file_input_ref" accept=".json,.yaml,.yml,.xls,.xlsx,.csv,.txt" @change="handleFileChange($event,p)" type="file" placeholder="Select file"/>
                </div>
                <input v-else-if="! (p.name.toLowerCase().includes('password') || p.name.toLowerCase().includes('secret') || p.name.toLowerCase().includes('token'))" class="input_output_fields" v-model="p.value" style="width: 100%" :disabled="((disabled_ref.valueOf()) && (! props.is_execute))" :placeholder="getPlaceHolder()"/>
            </div>

            <div class="input_output_fields_container ">
                <i class="fa-light fa-trash class_delete_input_output"  @click="delete_row(index)" v-if="! disabled_ref.valueOf()"/>
            </div>
        </div>
    </div>

</template>

<script setup>
    import { ref } from 'vue';
    const taskStore = useTaskStore();

    const props = defineProps({
        list: {
            type: Array,
            required: true
        },
        header: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String, // Whether this is input or output.
            required: true
        },
        is_execute: {
            type: Boolean,
            default: false
        }
    });

    if (props.is_execute) {
        props.list.forEach((element,index) => {
            if (! element.hasOwnProperty('param_type')) {
                element.param_type = 'String';
            }
        });
    }

    const add_empty_row = () => {
        props.list.valueOf().push({'name': '', 'default_value': '', 'assignment': '', 'param_type': 'String', 'required': false});
    }

    const delete_row = (k) => {
        props.list.valueOf().splice(k,1);
    }

    const disabled_ref = ref(false);
    if (props.readonly) {
        disabled_ref.value = true;
    }

    if ((props.is_execute) && (props.type == 'input')) {
        props.list.forEach((input_param) => {
            /*
            When this code is run, we are rendering the parameters for execution, and the result is likely coming from 
            localStorage, and the type can be array or object, etc.  In those cases, we need to serialize it back to 
            string so that they are displayed in the input field appropriately using v-model.  Otherwise, arrays will 
            loose the square brackets, and object will be rendered as [object Object]
            */

            try {
              if ((typeof(input_param.value) != 'string') && (input_param.param_type != 'Dropdown')) {
                  input_param.value = JSON.stringify(input_param.value);
              }

              if (input_param.param_type == 'Dropdown')  {
                  if (Array.isArray(input_param.value)) {
                      input_param.options = input_param.value;
                  } else {
                      input_param.options = JSON.parse(JSON.stringify(jsyaml.load(input_param.default_value)));
                  }
                  if ((typeof(input_param.value) == 'undefined') || (input_param.value == '')) {
                    input_param.value = input_param.options[0];
                  }
              }
            } catch (e) {
              // Ignore this exception for now.
            }
        });
    }
    const maxFileSize = 10 * 1024 * 1024; // Maximum file size of 10 MB
    const handleFileChange = (event,p) => {

        const file = event.target.files[0]; // Get the first selected file

        if (!file || file.size > maxFileSize) {
            dkAlert("File is too large. Maximum size is 10 MB.");
            return; // No file selected
        }

        // Check if the selected file has a .json or .yaml extension
        if (file.name.toLowerCase().search(/\.(json|yaml|yml|xls|xlsx|csv|txt)$/)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    let content = e.target.result;
                    let type = content.substring(5, content.indexOf('base64,') - 1);
                    content = content.substring(content.indexOf('base64,') + 7);
                    let result_obj = {};
                    result_obj['name'] = file.name;
                    result_obj['content'] = content;
                    result_obj['type'] = type;
                    p.value = result_obj;
                    //console.log("🚀 ~ file: ParamsList.vue:143 ~ p=> :", p)
                } catch (error) {
                    console.error('Error reading file content:', error);
                }
            };

            //reader.readAsText(file); // Read the file as text
            reader.readAsDataURL(file); // Read the file as based64
        } else {
            dkAlert("Invalid file format. Please select a .json or .yaml file.");
            return;
        }
    }

    const getPlaceHolder = ()=> {

        const parent_task_ID = taskStore.getTask.id
        const active_task_ID = taskStore.getActiveTab.id

        if(parent_task_ID === active_task_ID){
            return props.type === 'input' ? 'default value':'variable name';
        }

        if(parent_task_ID !== active_task_ID){

            return props.type === 'input' ? 'variable name':'variable name';
        }        
    }
</script>

<style scoped>
    .input_output_bg {
        background-color: white;
        color: black;
        font-weight: bold;
    }

    .inputs {
        background-color: #eaeaea;
        color: black;
        font-weight: bold;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .outputs {
        background-color: #eaeaea;
        color: black;
        font-weight: bold;
        padding-top: 3px;
        padding-bottom: 3px;
    }


    .input_output_fields {
        
        /* margin: 5px;
        border: none;
        border-radius: 5px; */
        padding-left: 10px;
        border: none;

    }
    .class_param_name {
        width: 50%;
    }
    .read_only .input_output_fields {
        background: #dedede !important;
    }

    .input_output_fields:disabled {
        background: #dedede !important;
    }

    .input_output_fields_container {
        /*
        width: calc((100% - 100px)/2);
        margin: 0px 20px;
        display: inline-block;
        padding: 5px;
        padding: 5px;
        */
        align-self: center;
    }


    .white_bottom {
        /* border-bottom: 1px solid white; */
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
    .white_bottom.inputs {
        border-top: 1px solid #ccc;
    }

    .fa-plus {
        top: 7px;
        right: 10px;
        position: absolute;         
        cursor: pointer;
    }
    .fa-trash {
        /*
        top: 20px;
        right: 10px;
        position: absolute;         
        */
        cursor: pointer;
    }    

    .input_output_fields::placeholder {
        opacity: 0.5;
        font-style: italic;
    }

    label {
        font-weight: normal;
    }
    .class_input_type_selector {
        /* border-radius: 5px;        */
        background: transparent !important;
        border-right: 1px solid #ccc;
    }
    .class_input_type_selector .custom-select {
        border: none;
        /* border-radius: 5px;
        width: 160px; 
        background: white; 
        color: black; 
        border-radius: 5px; 
        margin: 5px 10px; */
    }
    .class_required_field_checkbox {
        background: transparent !important;
        display: none; /* We decided to get rid of required fields */
    }
    .class_delete_input_output {
       padding-right: 5px;
    }
    .input_output_fields_container .fa-solid {
        color: gray;
    }

</style>
