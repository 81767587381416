export const addUsersToTask = async (task_id, user_perms) => {
    // Lets the "author" of a task invite other users to a task
    const result_task = await logFetchAJAX(getUrl(`/api/tasks/${task_id}/users`), { //use getUrl() logFetchAJAX()
        method: 'PUT',
        body: {
          "added_permissions": user_perms,
          "recursive": true,
        }
    });
  
  return result_task;
}

