export const suggestActions = async (url_prefix, title, description, output_type, optional_prompt, runbook_task_id, index_path, send_exec_result_to_llm) => {
    output_type = output_type || "command"
    optional_prompt = optional_prompt || ""
    title = title || ""
    description = description || ""
    index_path = index_path || "0"
    runbook_task_id = runbook_task_id || ""
    
    let url = getUrl(`${url_prefix}/api/tasks/suggest`) + '?output_type=' + output_type;
    if (title.length > 0)
      url += "&title=" + encodeURI(title)
    if (description.length > 0)
      url += "&description=" + encodeURI(description)
    if (index_path)
      url += "&index=" + index_path
    if (runbook_task_id)
      url += "&runbook_task_id=" + runbook_task_id
    if (send_exec_result_to_llm)
      url += "&send_exec_result_to_llm=" + send_exec_result_to_llm

    const response = await logFetchAJAX(url, {});
    return response
}
