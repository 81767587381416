export const updateTask = async (taskId, taskCache, patch, update_mask, stops) => {
    // A little hack to handle changes in commands
    patch = patch || {}
    update_mask = update_mask || []
    if ((patch.full_command || "") != (patch.commands || []).join("\n")) {
      patch.commands = patch.full_command.split("\n")
    }
    const updated_task = await logFetchAJAX(getUrl(`/api/tasks/${taskId}/`), {
      method: "PATCH",
      body: {
        "task": patch, 
        "update_mask": update_mask,
        "sub_task_ops": stops,
      }
    })
    taskCache[taskId] = updated_task["task"]
    hydrateTask(updated_task, taskCache);
    return updated_task
}
